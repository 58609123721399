import { createI18n } from 'vue-i18n'

import en from './assets/i18n/en.json';
import de from './assets/i18n/de-DE.json';
import fr from './assets/i18n/fr.json';
import pl from './assets/i18n/pl.json';
import nl from './assets/i18n/nl.json';
import nlBE from './assets/i18n/nl-BE.json';

export default createI18n({
    legacy: false,
    locale: localStorage.getItem('language') || 'de-DE',
    messages: {
        'de-DE': de,
        en: en,
        fr: fr,
        pl: pl,
        nl: nl,
        'nl-BE': nlBE,
    }
})