export default [
  {
    component: 'CNavItem',
    name: 'customerCard.header',
    to: '/customerCard',
    icon: 'cil-contact',
  }, {
    component: 'CNavItem',
    name: 'news.header',
    to: '/news',
    icon: 'cil-newspaper',
    if: 'news',
  }, {
    component: 'CNavItem',
    name: 'customerData.header',
    to: '/customerData',
    icon: 'cil-notes',
  }, {
    component: 'CNavItem',
    name: 'customerVisits.header',
    to: '/customerVisits',
    icon: 'cil-chart',
  }, {
    component: 'CNavItem',
    name: 'locations.header',
    to: '/locations',
    icon: 'cil-map',
  }, {
    component: 'CNavItem',
    name: 'imprint.header',
    to: '/imprint',
    icon: 'cil-notes',
  }, {
    component: 'CNavItem',
    name: 'dataPrivacy.header',
    to: '/dataPrivacy',
    icon: 'cil-notes',
  }, {
    component: 'CNavItem',
    name: 'termsOfUse.header',
    to: '/termsOfUse',
    icon: 'cil-notes',
  },
//    {
//    component: 'CNavItem',
//    name: 'surveys.header',
//    to: '/surveys',
//    icon: 'cil-chat-bubble',
//  }, {
//    component: 'CNavItem',
//    name: 'feedback.header',
//    to: '/feedback',
//    icon: 'cil-notes',
//  }, {
//    component: 'CNavItem',
//    name: 'recommendations.header',
//    to: '/recommendations',
//    icon: 'cil-happy',
//  },
  {
    component: 'CNavItem',
    name: 'settings.header',
    to: '/settings',
    icon: 'cil-settings',
  }, {
    component: 'CNavItem',
    name: 'sales.header',
    to: '/salesOverview',
    icon: 'cil-chart',
    if: 'sales',
  }, {
    component: 'CNavItem',
    name: 'employee.header',
    to: '/employeesOverview',
    icon: 'cil-chart',
    if: 'sales',
  },
]
