import {defineStore} from "pinia";
import axios from "axios";

export const useCardDataStore = defineStore("cardData", {
    state:   () => {
        return {
            general:        {
                init:       false,
                deviceID:   false,
                cardID:     false,
                appVersion: false,
                login:      false,
                language:   'de-DE',
            },
            menuStates: {
                news: true,
                sales: false,
            },
            texts:          {
                dataPrivacy:  '',
                imprint:      '',
                imprintImage: '',
                termsOfUse:   '',
            },
            theming:        {
                version:               '',
                background:            false,
                color:                 false,
                headerBackground:      false,
                headerColor:           false,
                menuTopColor:          false,
                menuContentBackground: false,
                menuContentColor:      false,
            },
            card:           {
                lastUpdated: '',
                balance:     '',
                date:        '',
            },
            user:           {
                salutation:  '',
                birthday:    '',
                name:        '',
                firstName:   '',
                street:      '',
                zip:         '',
                city:        '',
                numberPlate: '',
                email:       '',
                phone:       '',
                mobile:      '',
            },
            visits:         [{
                id:      0,
                program: '',
                date:    '',
                time:    ''
            }],
            news:           {
                image: '',
                text:  ''
            },
            location:       {
                locations:      [],
                locationSelect: [],
            },
            updatePassword: {
                oldPassword:    '',
                newPassword:    '',
                doublePassword: '',
            },
            sales: {
                salesData: [],
            },
            employees: {
                employeesData: [],
            }
        };
    },
    actions: {

        nl2br(str, replaceMode, isXhtml) {
            let breakTag   = (isXhtml) ? '<br />' : '<br>';
            let replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
        },
        formatDate(dateString) {
            let date = new Date(dateString);
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        resetApp() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then(function (registrations) {
                    for (let registration of registrations) {
                        registration.update()
                    }
                })
            }
            window.location.reload(true);
        },
        logout() {
            this.general.cardID   = '';
            this.general.deviceID = '';
            this.general.login    = false;
            localStorage.removeItem('cardID');
            localStorage.removeItem('deviceID')
        },
        initStore() {

            if (this.general.init === false) {

                this.theming.version               = window.localStorage.getItem('themeVersion');
                this.theming.background            = window.localStorage.getItem('background');
                this.theming.color                 = window.localStorage.getItem('color');
                this.theming.headerBackground      = window.localStorage.getItem('headerBackground');
                this.theming.headerColor           = window.localStorage.getItem('headerColor');
                this.theming.menuTopColor          = window.localStorage.getItem('menuTopColor');
                this.theming.menuContentBackground = window.localStorage.getItem('menuContentBackground');
                this.theming.menuContentColor      = window.localStorage.getItem('menuContentColor');
                this.general.appVersion            = window.localStorage.getItem('appVersion');
                this.general.cardID                = window.localStorage.getItem('cardID');
                this.general.deviceID              = window.localStorage.getItem('deviceID');
                this.general.language              = window.localStorage.getItem('language');
                if (this.general.cardID && this.general.deviceID) {
                    this.general.login = true;
                }
                this.card.balance      = this.t('cardData.cardBalance');
                this.card.date         = this.t('cardData.date');
                this.visits[0].program = this.t('cardData.visitsProgram');
                this.sales.salesData = [{
                    SiteName: '',
                    Amount: '',
                    ArticleName: this.t('sales.loadingData') ,
                    SalesPrice: '',
                }];
                this.employees.employeesData = [{
                    Site: '',
                    Employee: '',
                    DateSpan: this.t('employee.loadingData') ,
                    WorkingTime: '',
                }];

                this.fetchTexts();
                this.fetchLocations();
                this.general.init = true;
            }

            this.fetchSalesPermissions();
            setInterval(this.fetchSalesPermissions, 60000);
        },
        async fetchData(apiEndpoint) {

            let self = this;
            try {
                return axios.get(localStorage.getItem('host') + '/api/' + apiEndpoint + '?deviceID=' + this.general.deviceID + '&cardID=' + this.general.cardID).then((response) => {

                    // theme check
                    let themeVersion = response.data.themeVersion;
                    if (String(themeVersion) !== String(this.theming.version) && apiEndpoint !== 'theming') {
                        console.log('new theming available ' + themeVersion + ' ' + this.theming.version);
                        self.fetchTheming();
                    }

                    // app version check
                    let appVersion = response.data.appVersion;
                    if (appVersion !== this.general.appVersion) {

                        console.log('new app available' + appVersion + ' ' + this.general.appVersion);
                        this.general.appVersion = appVersion;
                        localStorage.setItem('appVersion', appVersion);
                        self.resetApp();
                    }

                    return response;
                });
            } catch (error) {
                console.log(error)
            }
        },
        async postData(apiEndpoint, postData) {

            //let self = this;
            try {
                return axios.post(localStorage.getItem('host') + '/api/' + apiEndpoint + '?deviceID=' + this.general.deviceID + '&cardID=' + this.general.cardID, postData).then((response) => {

                    return response;
                });
            } catch (error) {
                console.log(error)
            }
        },
        createCustomerCard(siteID, password) {

            let self = this;
            return self.postData('createCustomerCard', {siteID, password})
                       .then(function (response) {

                           return response;
                       });

        },
        changePassword(passwordNow, passwordNew) {

            let self = this;
            return self.postData('changePassword', {passwordNow, passwordNew})
                       .then(function (response) {

                           return response;
                       });

        },
        changeData() {

            let self = this;
            return self.postData('changeData', self.user)
                       .then(function (response) {

                           return response;
                       });

        },
        fetchTheming() {

            let self = this;
            self.fetchData('theming').then(function (response) {

                localStorage.setItem('appVersion', response.data.appVersion);
                localStorage.setItem('themeVersion', response.data.themeVersion);
                localStorage.setItem('background', response.data.data.background);
                localStorage.setItem('color', response.data.data.color);
                localStorage.setItem('background', response.data.data.background);
                localStorage.setItem('color', response.data.data.color);
                localStorage.setItem('headerBackground', response.data.data.header_background);
                localStorage.setItem('headerColor', response.data.data.header_color);
                localStorage.setItem('menuTopColor', response.data.data.menu_top_color);
                localStorage.setItem('menuContentBackground', response.data.data.menu_content_background);
                localStorage.setItem('menuContentColor', response.data.data.menu_content_color);
                if( localStorage.getItem('language') !== response.data.data.language ) {
                    localStorage.setItem('language', response.data.data.language);
                    self.resetApp();
                }
                self.general.init = false;
                self.initStore();
            });

        },
        fetchBalanceData() {

            let self = this;
            this.fetchData('cash').then(function (response) {

                let data              = response.data.data.GetContactBalanceResult.CardBalanceInfo;
                let t                 = data.Date.split(/[- :]/);
                let lastDate          = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
                self.card.balance     = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(data.Balance);
                self.card.date        = lastDate.getDate() + '.' + (lastDate.getMonth() + 1) + '.' + lastDate.getFullYear() + ' ' + lastDate.getHours() + ':' + (lastDate.getMinutes() < 10 ? '0' : '') + lastDate.getMinutes();
                self.card.lastUpdated = new Date();
            });

        },
        fetchCustomerVisits() {

            let self = this;
            this.fetchData('visits').then(function (response) {

                let data = response.data.data.GetContactHistoryResult.CardHistoryInfo;
                let id   = 0;

                let isArray = Array.isArray(data)

                if (isArray === true) {

                    self.visits = data.map(function (entry) {
                        let timeString = entry.Date.replace(' ', 'T');
                        let date       = new Date(timeString);
                        return {
                            id:      id++,
                            program: entry.Article,
                            date:    date.toLocaleDateString(),
                            time:    date.toLocaleTimeString(),
                        }
                    })
                } else {
                    self.visits = [{
                        id:      1,
                        program: self.t('cardData.noVisitsProgram'),
                        date:    '',
                        time:    '',
                    }]
                }

            });

        },
        fetchCustomerData() {

            let self = this;
            return new Promise((resolve) => {

                self.fetchData('customerData').then(function (response) {

                    let data = response.data.data.GetContactInfoNEWResult.CardContactInfo2;

                    self.user.salutation  = data.Salutation;
                    self.user.birthday    = data.Birthday.split(' ')[0];
                    self.user.name        = data.Name1;
                    self.user.firstName   = data.Name2;
                    self.user.street      = data.Street;
                    self.user.zip         = data.ZIP;
                    self.user.city        = data.City;
                    self.user.numberPlate = data.NumberPlate;
                    self.user.email       = data.Email;
                    self.user.phone       = data.Phone;
                    self.user.mobile      = data.Mobile;

                    resolve();
                });
            })

        },
        fetchNews() {

            let self = this;
            this.fetchData('news').then(function (response) {

                let data = response.data.data.GetSiteNewsResult.SiteInfoNews;

                if( Array.isArray( data ) ) {
                    data = data[0];
                }

                self.news.image = data.NewsImage;
                self.news.text  = data.NewsText;
            });

        },
        fetchTexts() {

            let self = this;
            this.fetchData('texts').then(function (response) {

                let data                = response.data.data;
                self.texts.imprint      = data.imprint;
                self.texts.dataPrivacy  = data.dataPrivacy;
                self.texts.termsOfUse   = data.termsOfUse;
                self.texts.imprintImage = data.imprintImage.src
                self.texts.logoImage    = data.logoImage.src
            });

        },
        fetchSalesPermissions() {

            let self = this;
            this.fetchData('salesPermission').then(function (response) {

                let data = response.data.data.IsSalesOverviewPermittedResult.IsSalesOverviewPermittedResult;

                if (data.ErrorCode === 'OK') {
                    if (data.IsPermitted === 1) {
                        self.menuStates.sales = true;
                        self.fetchSalesData();
                        self.fetchEmployeeData();
                    }
                } else {
                    self.menuStates.sales = false;
                }

            });

        },
        fetchSalesData() {


            let self = this;
            this.fetchData('salesData').then(function (response) {

                let data = response.data.data;
                self.sales.salesData = data;
          });

        },
        fetchEmployeeData() {

            let self = this;

            this.fetchData('employeeData').then(function (response) {

                let data = response.data.data;
                self.employees.employeesData = data;
            });

        },
        fetchLocations() {

            let self = this;
            let id   = 0;
            this.fetchData('locations').then(function (response) {

                let locations                = response.data.data.GetSiteInfoResult.SiteInfo;
                self.location.locations      = [];
                self.location.locationSelect = [];

                if (!Array.isArray(locations)) {
                    locations = [locations];
                }

                locations.map(function (entry) {

                    entry.id = id++;

                    entry.articles = [];
                    entry.images   = [];

                    let articleID = 1;
                    let imageID   = 1;

                    if( Array.isArray( entry.ArticleList.SiteArticleInfo ) ) {
                        entry.ArticleList.SiteArticleInfo.map(function (article) {
                            entry.articles.push({
                                id:          articleID++,
                                program:     article.Article,
                                price:       article.SalesPrice,
                                orderNumber: article.OrderNo,
                            })
                        })
                    }

                    if ( Array.isArray(entry.ImageList) ) {

                        entry.ImageList.SiteImageInfo.map(function (image) {
                            if (image.IsLogo >= 0) {
                                entry.images.push({
                                    id:  imageID++,
                                    src: image.Image,
                                })
                            }
                        })

                    }

                    self.location.locations.push(entry);
                });

                self.location.locationSelect.push(self.t('cardData.selectLocation'));

                self.location.locations.forEach((location) => {
                    self.location.locationSelect.push({label: location.Name1 + location.Name2, value: location.ID})
                })

            });

        },
    },
    getters: {},
});