<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand :style="'background-color: ' + cardDataStore.theming.menuTopColor + ';'">
      <img :height="48" :src="cardDataStore.texts.logoImage"/>
    </CSidebarBrand>
    <AppSidebarNav v-if="cardDataStore.general.login" :login="true" />
    <AppSidebarNav v-if="!cardDataStore.general.login" :login="false" />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import {useCardDataStore} from "@/stores/cardData";
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore();
    const cardDataStore = useCardDataStore();

    watch(
        () => cardDataStore.menuStates,
        () => {
          console.log('update');
          const instance = getCurrentInstance();
          instance?.proxy?.$forceUpdate();
        },
        { deep: true }
    )

    return {
      logoNegative,
      sygnet,
      cardDataStore,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
