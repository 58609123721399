import { createApp } from 'vue'
import { createPinia } from "pinia";
import { useI18n } from 'vue-i18n';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import CoreuiVue from '@coreui/vue-pro';
import CIcon from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';
import DocsCallout from '@/components/DocsCallout';
import DocsExample from '@/components/DocsExample';
import './registerServiceWorker';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueQrcodeReader from "../node_modules/vue3-qrcode-reader";

const app = createApp(App)
app.use(createPinia().use(() => {
    const { t } = useI18n()
    return { t }
}));
app.use(i18n);
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueQrcodeReader)
app.provide('icons', icons)
app.component('JDatepicker', Datepicker);
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)

app.mount('#app')
