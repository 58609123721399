<template>
  <AppSidebar />
  <div
      class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <AppHeader :showBurger="true" />
    <!--- <div class="body flex-grow-1 px-3"> --->
    <div class="body flex-grow-1 m-0 p-0" :style="'background: ' + store.theming.background + ';'">
      <router-view />
    </div>
  </div>
</template>
<script>

import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

import { useRoute } from 'vue-router';
import {useCardDataStore} from "@/stores/cardData";

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar,
  },
  setup() {
    const route      = useRoute();
    const store      = useCardDataStore();
    store.theming.showBurger = true;

    if( route.path === '/login' ) {
      store.theming.showBurger = false;
    }

    return {
      store,
    }
  }
}
</script>