<template>
  <span>{{ $t(text) }}</span>
</template>

<script>
export default {
  name: "TextComponent",
  props: ['text']
}
</script>

<style scoped>

</style>