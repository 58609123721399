export default [
{
    component: 'CNavItem',
    name: 'login.header',
    to: '/login',
    icon: 'cil-map',
  },{
    component: 'CNavItem',
    name: 'locations.header',
    to: '/locations',
    icon: 'cil-map',
}, {
    component: 'CNavItem',
    name: 'imprint.header',
    to: '/imprint',
    icon: 'cil-notes',
}, {
    component: 'CNavItem',
    name: 'dataPrivacy.header',
    to: '/dataPrivacy',
    icon: 'cil-notes',
}, {
    component: 'CNavItem',
    name: 'termsOfUse.header',
    to: '/termsOfUse',
    icon: 'cil-notes',
},
]
