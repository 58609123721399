import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/customerCard',
    children: [
      {
        path: '/customerCard',
        name: 'customerCard.header',
        component: () =>
          import('@/views/carwashapp/CustomerCard.vue'),
      },
      {
        path: '/news',
        name: 'news.header',
        component: () =>
          import('@/views/carwashapp/News.vue'),
      },
      {
        path: '/customerData',
        name: 'customerData.header',
        component: () =>
          import('@/views/carwashapp/CustomerData.vue'),
      },
      {
        path: '/customerVisits',
        name: 'customerVisits.header',
        component: () =>
          import('@/views/carwashapp/CustomerVisits.vue'),
      },
      {
        path: '/locations',
        name: 'locations.header',
        component: () =>
          import('@/views/carwashapp/Locations.vue'),
      },
      {
        path: '/imprint',
        name: 'imprint.header',
        component: () =>
          import('@/views/carwashapp/Imprint.vue'),
      },
      {
        path: '/dataPrivacy',
        name: 'dataPrivacy.header',
        component: () =>
          import('@/views/carwashapp/DataPrivacy.vue'),
      },
      {
        path: '/termsOfUse',
        name: 'termsOfUse.header',
        component: () =>
          import('@/views/carwashapp/TermsOfUse.vue'),
      },
      {
        path: '/surveys',
        name: 'surveys.header',
        component: () =>
          import('@/views/carwashapp/Surveys.vue'),
      },
      {
        path: '/feedback',
        name: 'feedback.header',
        component: () =>
          import('@/views/carwashapp/Feedback.vue'),
      },
      {
        path: '/recommendations',
        name: 'recommendations.header',
        component: () =>
          import('@/views/carwashapp/Recommendations.vue'),
      },
        {
        path: '/settings',
        name: 'settings.header',
        component: () =>
          import('@/views/carwashapp/Settings.vue'),
      },
        {
        path: '/updatePassword',
        name: 'changePassword.header',
        component: () =>
          import('@/views/carwashapp/ChangePassword.vue'),
      },
        {
        path: '/registration',
        name: 'registration.form.header',
        component: () =>
            import('@/views/carwashapp/pages/Registration.vue'),
      },
        {
        path: '/login',
        name: 'login.header',
        component: () =>
            import('@/views/carwashapp/pages/Login.vue'),
      },
        {
        path: '/barcodeLogin',
        name: 'barcodeLogin.header',
        component: () =>
            import('@/views/carwashapp/pages/BarcodeLogin.vue'),
      },
        {
        path: '/salesOverview',
        name: 'sales.header',
        component: () =>
            import('@/views/carwashapp/Sales.vue'),
      },
        {
        path: '/employeesOverview',
        name: 'employee.header',
        component: () =>
            import('@/views/carwashapp/Employee.vue'),
      },{
            path: '/debug',
            name: 'Debug',
            component: () =>
                      import('@/views/carwashapp/pages/Debug.vue'),
        }
    ],
  },{
    path: '/theming',
    name: 'Theming',
    component: () =>
        import('@/views/carwashapp/pages/Theming.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
