import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import nav from '@/_nav'
import nav_login from '@/_nav_login.js'
import TextComponent from "@/views/carwashapp/TextComponent";

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue-pro'
import {useCardDataStore} from "@/stores/cardData";

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
    props: {
      login: Boolean,
    },
  setup(props) {
    const route = useRoute()
    const firstRender = ref(true)
    const store = useCardDataStore();

    onMounted(() => {
      firstRender.value = false
    })

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: 'nav-icon',
                name: item.icon,
              }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) => {
                if( ('if' in item && store.menuStates[item.if] ) || !('if' in item) ) {
                    return h(
                        resolveComponent(item.component),
                        {
                            active: props.isActive,
                            href: props.href,
                            style: 'background-color: ' + store.theming.menuContentBackground + ';',
                            onClick: () => props.navigate(),
                        },
                        {
                            default: () => [
                                item.icon &&
                                h(resolveComponent('CIcon'), {
                                    customClassName: 'nav-icon',
                                    name: item.icon,
                                    style: 'color: ' + store.theming.menuContentColor + ';',
                                }),
                                item.name && h('span', {
                                        style: 'color: ' + store.theming.menuContentColor + ';',
                                    },
                                    h(TextComponent, { text: item.name }),
                                ),
                                item.badge &&
                                h(
                                    CBadge,
                                    {
                                        class: 'ms-auto',
                                        color: item.badge.color,
                                    },
                                    {
                                        default: () => item.badge.text,
                                    },
                                ),
                            ],
                        },
                    )
                }
              }
            },
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            },
          )
    }

    if( props.login ) {

        return () =>
            h(
                CSidebarNav,
                {
                    style: 'background-color: ' + store.theming.menuContentBackground + ';',
                },
                {
                    default: () => nav_login.map((item) => renderItem(item)),
                },
            )
    } else {
        return () =>
            h(
                CSidebarNav,
                {
                    style: 'background-color: ' + store.theming.menuContentBackground + ';',
                },
                {
                    default: () => nav.map((item) => renderItem(item)),
                },
            )
    }
  },
})
export { AppSidebarNav }
