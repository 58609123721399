<template>
  <CToaster placement="top-end">
    <CToast v-for="(toast, index) in toastStore.toasts" :key="index">
      <CToastHeader v-if="toast.type==='success'" style="background: #0B8A20FF; color:white;" closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
      </CToastHeader>
      <CToastHeader v-if="toast.type==='error'" style="background: #c20c0c; color:white;" closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
      </CToastHeader>
      <CToastHeader v-if="toast.type==='warning'" style="background: #dcc12b; color:white;" closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
      </CToastHeader>
      <CToastBody>
        {{ toast.content }}
      </CToastBody>
    </CToast>
  </CToaster>
  <COffcanvas placement="bottom" :visible="visibleBottom" @hide="() => { visibleBottom = !visibleBottom }">
    <COffcanvasHeader>
      <COffcanvasTitle>{{ $t('app.install.installApp') }}</COffcanvasTitle>
      <CCloseButton class="text-reset" @click="() => { visibleBottom = false }"/>
    </COffcanvasHeader>
    <COffcanvasBody>
      {{ $t('app.install.installText') }}<br/><br/>
      <button @click="install">{{ $t('app.install.installAppButton') }}</button>
      <br/><br/>
      <button @click="dismiss">{{ $t('app.install.notNowButton') }}</button>
    </COffcanvasBody>
  </COffcanvas>
  <router-view/>
</template>
<script>
import {ref, watch, onMounted} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from "vue-router";
import {useCardDataStore} from "@/stores/cardData";
import {useToastStore} from "@/stores/toast";

export default {
  setup() {

    const store = useStore()
    const router = useRouter();
    const dataStore = useCardDataStore();
    const toastStore = useToastStore();
    const deferredPrompt = ref(null);
    const visibleBottom = ref(false);

    dataStore.initStore();

    console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'development') {
      window.localStorage.setItem('host', 'http://localhost')
    } else {
      window.localStorage.setItem('host', '')
    }

    if (!dataStore.theming.background || !dataStore.theming.color || !dataStore.theming.headerBackground || !dataStore.theming.headerColor) {
      router.push('/theming');
    } else {

      if (!dataStore.general.login) {
        router.push('/login');
      }

    }

    let platform = navigator.platform;
    console.log(platform);
    /*
    if (platform === 'iPhone' || platform === 'iPad') {
      // Die App ist noch nicht installiert
      if (!navigator.standalone) {
        let lastShown = parseInt (localStorage.getItem ('lastShown'));
        let now = new Date().getTime ();
        // lastShown NaN – App wurde noch nie geladen und Anweisung seit 7 Tagen nicht gezeigt
        if (isNaN (lastShown) || (lastShown + 1000 * 60 * 60 * 24 * 7) <= now) {
          document.getElementById("instructions").style.display = "block";
          localStorage.setItem ("lastShown", now);
        }
      }
    }
    */

    watch(store.state, () => {
      store.state.theme === 'dark'
          ? document.body.classList.add('dark-theme')
          : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')

    async function dismiss() {
      deferredPrompt.value = null;
      visibleBottom.value = false;
    }

    async function install() {
      deferredPrompt.value.prompt();
      visibleBottom.value = false;
    }

    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt.value = e;
      visibleBottom.value = true;
    });

    window.addEventListener("appinstalled", () => {
      deferredPrompt.value = null;
    });

    onMounted(() => {
      let isInWebAppiOS = (window.navigator.standalone === true);
      let isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);

      if (!isInWebAppiOS && !isInWebAppChrome && deferredPrompt.value !== null) {
        visibleBottom.value = true;
      }
    });

    return {
      dataStore,
      toastStore,
      visibleBottom,
      dismiss,
      install,
    }
  },

}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
