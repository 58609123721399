<template>
  <CHeader position="sticky" :style="'background: ' + store.theming.headerBackground + '; color: ' + store.theming.headerColor">
    <CContainer fluid>
      <CHeaderToggler class="align-self-center pt-2" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="xl" style="color: white" />
      </CHeaderToggler>
        <div style="text-align: center;">
        <b>{{ $t(breadcrumbs) }}</b>
        </div>
      <CHeaderBrand class="d-lg-none align-self-center" to="/">
        <img height="48" :src="store.texts.logoImage"/>
      </CHeaderBrand>
    </CContainer>
  </CHeader>

</template>

<script>

import { logo } from '@/assets/brand/logo'
import { onMounted, ref } from 'vue'
import router from '@/router'
import {useCardDataStore} from "@/stores/cardData";

export default {
  name: 'AppHeader',
  setup() {

    const breadcrumbs = ref('');
    const store = useCardDataStore();

    const getBreadcrumbs = () => {
      return router.currentRoute.value.name;
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      store,
      logo,
      breadcrumbs,
    }
  },
}
</script>
