import { defineStore } from "pinia";

export const useToastStore = defineStore("toastData", {
    state: () => {
        return {
            toasts: [],
        }
    },
    actions: {

        w(content) {
            this.addToast(this.t('toast.warning'), content, 'warning');
        },
        e(content) {
            this.addToast(this.t('toast.error'), content, 'error');
        },
        s(content) {
            this.addToast(this.t('toast.success'), content, 'success');
        },
        addToast(title, content, type) {
            this.toasts.push({ title, content, type });
        }
    },
    getters: {},
});